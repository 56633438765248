import * as React from 'react'
import styled from 'styled-components'

interface SwatchProps {
  title: string
  colour: string
  variations?: Array<string>
  darkText?: boolean
  shadow?: boolean
}

interface SwatchStyleProps {
  darkText?: boolean
  colour: string
  shadow?: boolean
}

interface VariationProps {
  colour: string
}

const SwatchContainer = styled.div`
  width: 25rem;
  height: 15rem;
  background: ${(props: SwatchStyleProps): string => props.colour || '#fff'};
  color: ${(props: SwatchStyleProps): string =>
    props.darkText ? '#000' : '#fff'};
  box-shadow: 0 8px 10px 0 rgba(43, 25, 22, 0.12);
  border: ${(props: SwatchStyleProps): string =>
    props.darkText ? '1px solid #e6e6e6' : '1px solid transparent'};
  border-radius: 0.3rem;
  overflow: hidden;
  margin-right: 1rem;
  margin-bottom: 1rem;
`

const Labels = styled.div`
  padding: 1.2rem;
`

const SwatchInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ColourText = styled.div`
  font-size: 1.2rem;
  text-transform: uppercase;
`

const VariationsContainer = styled.div`
  display: flex;
`

const Variation = styled.div`
  flex: 1;
  height: 1.5rem;
  background: ${(props: VariationProps): string => props.colour || '#fff'};
`

const Swatch = (props: SwatchProps): JSX.Element => {
  const { title, colour, variations, darkText, shadow } = props
  return (
    <SwatchContainer colour={colour} darkText={darkText} shadow={shadow}>
      <SwatchInner>
        <Labels>
          <span>{title}</span>
          <ColourText>{colour}</ColourText>
        </Labels>
        <VariationsContainer role="group" className="swatch-variations">
          {variations &&
            variations.map((variation, index) => {
              return <Variation key={index} colour={variation} />
            })}
        </VariationsContainer>
      </SwatchInner>
    </SwatchContainer>
  )
}

export default Swatch
