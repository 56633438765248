import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import { isJapanese } from 'helpers/locale'
import * as React from 'react'
import styled, { css } from 'styled-components'

import Arrow from '../../images/svg/arrow-right.svg'
import Icon from '../atoms/icon'

const StyledPanel = styled.div`
  ${({ theme }) => css`
    position: relative;
    overflow: hidden;
    color: ${theme.colours.secondary};

    .gatsby-image-wrapper {
      height: 100%;
      min-height: 26rem;
      transition: all ${theme.animation.longLength} ease;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(to top, #27150f, rgba(0, 0, 0, 0));
        min-height: 50%;
      }
    }
    &:hover {
      .icon {
        opacity: 1;
      }
      .gatsby-image-wrapper {
        filter: blur(4px);
      }
    }
    h2 {
      font-size: 2.8rem;
      ${isJapanese() ?
        css`
          font-family: ${ theme.font.fontFamilyDefault };
          font-weight: ${ theme.font.fontWeightBold };
        `
        : css`font-family: ${ theme.font.fontFamilyAlt };`
      };
      margin: 0 0 0.9rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        font-size: 3.8rem;
      }
    }
    .content {
      line-height: 1.33;
      position: absolute;
      z-index: 2;
      bottom: 12%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      width: 100%;
      max-width: 44rem;
      padding: 0 2rem;
      @media only screen and ${theme.breakpoints.fromNormalScreen} {
        top: 40%;
        bottom: auto;
      }
      @media only screen and ${theme.breakpoints.fromXLargeScreen} {
        top: 55%;
      }
    }
    .icon {
      transition: opacity ${theme.animation.defaultLength} ease;
      opacity: 0;
      margin-bottom: 3rem;
    }
  `}
`

type PanelProps = {
  title: string
  body: string
  image: FluidObject | FluidObject[]
  imageAlt: string
  to: string
}

const Panel = ({
  title,
  body,
  image,
  imageAlt,
  to,
}: PanelProps): JSX.Element => (
  <StyledPanel>
    <Link to={to}>
      <Img alt={imageAlt} fluid={image} />
      <div className="content">
        <Icon className="icon">
          <Arrow />
        </Icon>
        <h2>{title}</h2>
        {body}
      </div>
    </Link>
  </StyledPanel>
)

export default Panel
