import * as React from 'react'
import styled from 'styled-components'

import Container from './container'

interface Props {
  title: string
  children: React.ReactNode
}

const Section = styled.div`
  padding-top: 6rem;
  margin-bottom: 10rem;
`

const Title = styled.h2`
  margin-bottom: 4rem;
  font-size: 2rem;
  letter-spacing: 0.1em;
  font-weight: bold;
  text-transform: uppercase;
`

const StyleGuideSection = ({ title, children }: Props): JSX.Element => {
  return (
    <Section>
      <Container>
        <Title>{title}</Title>
      </Container>
      {children}
    </Section>
  )
}

export default StyleGuideSection
